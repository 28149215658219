<div [@fadeIn] class="w-100 p-0 m-0 page-content bg-secondary">
    <img class="logo-image" src="/assets/img/logo-visacar-transparent.png" alt="">
    <div *ngIf="newUser && user" class="content-container m-0 card-container">
        <div class="card">
            <div class="row card-body">
                <section class="personal-info col-12">
                    <div class="info-container">
                        <h1>{{formatUserName()}}</h1>
                        <p>
                            <a href="mailto://{{user.email}}" class="user-email">{{newUser.email}}</a>
                        </p>
                        <h6 class="text-muted mt-3">Esta informação pode ser visível para o administrador do sistema.</h6>
                    </div>
                    <div class="pl-5">
                        <div class="image-container">
                            <img class="profile-image" [src]="newUser.picture || '/assets/img/user-profile-color.png'" alt="">
                        </div>
                    </div>
                </section>

                <div class="acount-container my-0">
                    <h5>Conta</h5>
                </div>

                <div class="line"></div>

                <section class="col-12 mt-3 row p-0 needs-validation">
                    <div class="mb-3 col-12">
                        <label for="test-name-validation" class="form-label">
                            Nome
                            <span class=" text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" [placeholder]="user.name || 'Primeiro Nome'" [(ngModel)]="newUser.name">
                        <div *ngIf="!isNameValid()" class="text-danger">O campo Nome é obrigatório.</div>
                    </div>
                    <app-input-contact
                        [(ngModel)]="newUser.phoneNumber"
                        [isMandatory]="true"
                        [showError]="!isPhoneNumberValid()"
                        [invertColor]="false"
                        [errorMessage]="'O campo Número de Contato é obrigatório'"
                    ></app-input-contact>

                    <div class="mb-3 col-12">
                        <label for="exampleFormControlInput1" class="form-label">
                            Endereço de Email
                            <span class=" text-danger">*</span>
                        </label>
                        <input type="text" class="form-control"
                            [placeholder]="user.email || 'exemplo@dominio.com'" [(ngModel)]="newUser.email">
                        <div *ngIf="!isEmailValid()" class="text-danger">O campo Endereço de Email é obrigatório.</div>
                    </div>

                    <div *ngIf="user.type == userRole.CLIENT" class="mb-3 col-12">
                        <label for="exampleFormControlInput1" class="form-label">
                            Cartão do Porto
                            <span class=" text-danger">*</span>
                        </label>
                        <span type="text" class="form-control bg-transparent">
                            {{ (newUser.portoCard || '0') | portoCard}}
                            </span>
                        <div *ngIf="!isPortoCardValid()" class="text-danger">
                            A versão digital do Cartão Porto. é obrigatória. Se ainda não o possui associado à sua conta, pode fazê-lo através do 
                            <a class="link-opacity-75" [href]="registerPortoCardUrl">Cartão Porto.</a>.
                        </div>
                    </div>

                    <app-input-nif
                    [(ngModel)]="newUser.nif"
                    ></app-input-nif>
                </section>

            </div>
            <div class="card-footer">
                <section class="save-changes-container text-center col-16 mt-4">
                    <button *ngIf="isUserValid(user) | async" class="btn btn-danger mx-3"
                        (click)="goToHome()">Cancelar</button>
                    <button *ngIf="!(isUserValid(user) | async)" class="btn btn-danger mx-3" (click)="signOut()">Sair</button>
                    <button class="btn btn-success mx-3" (click)="updateUser()">Guardar Alterações</button>
                </section>
            </div>
        </div>
    </div>
</div>
