import { inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable, of, switchMap } from 'rxjs';
import { UserRole } from '../models/user.model';
import { jwtDecode } from 'jwt-decode';
import { AuthSystem } from './auth-system';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

/**
 * @description
 * Firebase authentication system implementation extending AuthSystem.
 *
 * This class provides methods to interact with Firebase authentication services
 * and extends the base AuthSystem for common authentication functionality.
 */
export class AuthWithFirebase extends AuthSystem {
  /** Reference to the AngularFireAuth service. */
  override service: AngularFireAuth = inject(AngularFireAuth);

  /** Reference to the Angular Router service. */
  router: Router = inject(Router);

  /**
   * Constructs an instance of AuthWithFirebase.
   */
  constructor() {
    super();
  }

  /**
   * Overrides the signIn method from AuthSystem.
   * @returns An Observable<boolean> indicating the success of the sign-in operation.
   */
  override signIn(): Observable<boolean> {

    return this.service. idToken.pipe(
      switchMap((token: string | null) => {
        if (token != null) {
          this.setAuthType(UserRole.OPERATOR);
          this.userAuthToken$.next(token);
          this.retrieveUserFromLogin();
          // this.createUserFromLogin();
          return of(true);
        } else {
          this.userAuthToken$.next('');
          return of(false);
        }
      })
    );
  }

  /**
   * Overrides the signOut method from AuthSystem.
   * Signs out the user and performs necessary cleanup.
   */
  override signOut(): void {
    this.service.signOut();
    this.userAuthToken$.next('');
    this.removeAuthType();
    this.user$.next(null);

    // if(this.userService.user$?.value != null) {
    //   const user = this.userService.user$.value;
    //   user.id = "";

    //   this.userService.user$.next(user);
    // }

    this.userService.user$.next(null);
    setTimeout(() =>this.router.navigate(['/']));
  }

  /**
   * Overrides the isAuthTokenValid method from AuthSystem.
   * Checks if the stored authentication token is still valid.
   * @returns A boolean indicating whether the authentication token is valid.
   */
  override isAuthTokenValid(): boolean {
    
    const token = this.userAuthToken$.getValue();

    if(token == "") return false;

    const decoded: any = jwtDecode(token);

    if (!decoded || !decoded.exp) return false;

    const expiredDate = new Date(decoded.exp * 1000);

    return expiredDate.getTime() > new Date().getTime();
  }

  /**
   * Overrides the idToken method from AuthSystem.
   * @returns An Observable<string | null> representing the authentication token.
   */
  override idToken(): Observable<string | null> {
    return this.service.idToken;
  }
}
