import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyEuroPipe } from './currency-euro.pipe';
import { BankReferencePipe } from './bank-reference.pipe';
import { RoundPipe } from './round.pipe';


@NgModule({
  declarations: [
    CurrencyEuroPipe,
    BankReferencePipe,
    RoundPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CurrencyEuroPipe,
    BankReferencePipe,
    RoundPipe,
  ]
})
export class PipesModule { }
