<nav class="navbar navbar-expand-lg navbar-light custom-bottom-border" style="background-color: #474848;">
  <div *ngIf="user == null" class="container-fluid">
    <a class="navbar-brand" [routerLink]="'/'">
      <img class="logo-stcp" src="/assets/img/logo-stcp.png" alt="">
    </a>
  </div>

  <div *ngIf="user != null" class="container-fluid">
    <span *ngIf="user.type != userRole.VISA_CAR_OPERATOR" class="navbar-brand">
      <a *ngIf="!isHomePage()" class="navbar-brand text-white" (click)="goBack()" >
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
      </a>
      <a *ngIf="isHomePage()" class="navbar-brand" href="https://www.stcpservicos.pt/">
        <img class="logo-stcp" src="/assets/img/logo-stcp.png" alt="">
      </a>
    </span>


    <a *ngIf="user.type == userRole.VISA_CAR_OPERATOR" class="navbar-brand" href="https://www.visacar.pt/">
      <img src="assets/img/logo-visacar.png" alt="Visacar Logo" style="width: 50px; height: auto;">
    </a>
    <!-- Profile Alert buttons -->
    <!-- Mobile Mode -->
    <div *ngIf="isMobile" class="menu-header">
      <!-- <app-search-booking></app-search-booking> -->
      <app-alert-container [user]="user"></app-alert-container>
      <app-profile-button [user]="user"></app-profile-button>
    </div>

    <!-- Navbar items -->
    <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
      <ul class="navbar-nav">
      </ul>
    </div>

    <!-- Profile Alert buttons -->
    <!-- Portrait Mode -->
    <div *ngIf="!isMobile" class="menu-header">
      <app-search-booking></app-search-booking>
      <app-alert-container [user]="user"></app-alert-container>
      <app-profile-button [user]="user"></app-profile-button>
    </div>
  </div>
</nav>