import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  filter,
  interval,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Booking, BookingState } from '../models/booking.model';
import { ScheduleTimeSlot } from '../models/schedule-time-slot';
import { User, UserRole } from '../models/user.model';
import { ApiService } from './api.service';
// import { LocationService } from './location.service';
import { UserService } from './user.service';
import { Point } from '../models/point.model';
import { CompanionService } from './companion.service';
import { PointService } from './point.service';
import { Payment, PaymentStatus } from '../models/payment.model';


/**
 * BookingService
 *
 * This service provides functionality related to taxi bookings and schedules.
 * It interacts with the server through the ApiService to perform CRUD operations.
 *
 * Dependencies:
 * - ApiService: Handles HTTP requests to the server.
 * - UserService: Manages user-related data and authentication.
 * - TaxiService: Manages taxi-related data and operations.
 * - LocationService: Manages location-related data.
 * - PointService: Manages point-related data.
 * - CompanionService: Manages companion-related data.
 *
 * Usage:
 * - Use `retrieveAllActiveBookings` to get all active bookings for the authenticated user.
 * - Use `retrieveAllFinishedBookings` to get all finished bookings.
 * - Use `scheduleBookingNextSlot` to get the next available time slot for booking.
 * - Use `createNewBooking` to create a new booking object.
 * - Use `postNewBooking` to submit a new booking to the server.
 * - Use `isBookingValid` to check if a booking is valid.
 * - Use `operatorSearchBookingList` to search bookings based on date and search field.
 *
 * Properties:
 * - `bookings`: BehaviorSubject containing an array of Booking objects.
 * - `nextAvailableTimeSlot$`: BehaviorSubject containing the next available time slot.
 * - `selectedBookings$`: BehaviorSubject containing the currently selected booking.
 *
 * Note: All date and time values are represented using JavaScript Date objects.
 */

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  bookings: BehaviorSubject<Booking[]> = new BehaviorSubject<Booking[]>([]);
  // newBookingContact: BehaviorSubject<string> = new BehaviorSubject<string>('')
  // newBookingPortoCard: BehaviorSubject<string> = new BehaviorSubject<string>('')
  nextAvailableTimeSlot$: BehaviorSubject<ScheduleTimeSlot> =
    new BehaviorSubject<ScheduleTimeSlot>({
      interval: 0,
      latestSlot: 0,
      nextSlot: 0,
      serverTime: 0,
    });

  // private getAllBookingsUrl = 'http://localhost:5000/booking/get_all_bookings';
  private getAllBookingsEndpoint = 'booking/get_all_bookings';
  private getAliveRequestsEndpoint = 'requests/get_alive_requests';
  private getBookingByStartingLocationIdEndpoint = 'booking/get_bookings_by_start_location'
  private cancelBookingEndpoint = 'booking/cancel_booking'

  updateStartPositonEvent: Subject<Point> = new Subject<Point>();
  updateEndPositonEvent: Subject<Point> = new Subject<Point>();
  portoCardUser?: User;

  private loopNextAvailableTimeSlotSubscription?: Subscription;
  private lastBookinkRequestedTime: Date = new Date(0);

/**
   * Constructor
   *
   * @param api: ApiService - Handles HTTP requests.
   * @param userService: UserService - Manages user-related data.
   * @param taxiService: TaxiService - Manages taxi-related data.
   * @param locationService: LocationService - Manages location-related data.
   * @param pointService: PointService - Manages point-related data.
   * @param companionService: CompanionService - Manages companion-related data.
   */
  constructor(
    private http: HttpClient,
    private api: ApiService,
    private userService: UserService,
    // private locationService: LocationService,
    private companionService: CompanionService,
    private pointService: PointService
  ) {
    this.userService.user$
      .pipe(filter((user) => !!user))
      .subscribe((user: User | null) => {
        this.retrieveAllActiveBookings(user!)
      });
  }

  /**
   * Loop to update the next available time slot at regular intervals.
   * Subscribes to the result of `scheduleBookingNextSlot` and updates the BehaviorSubject.
   */
  loopNextAvailableTimeSlot() {
    const timeInterval = 15 * 60 * 1000;
    this.scheduleBookingNextSlot().subscribe((scheduleTimeSlot) =>
      this.nextAvailableTimeSlot$.next(scheduleTimeSlot)
    );

    if (this.loopNextAvailableTimeSlotSubscription) {
      this.loopNextAvailableTimeSlotSubscription.unsubscribe();
    }
    this.loopNextAvailableTimeSlotSubscription = interval(timeInterval)
      .pipe(switchMap(() => this.scheduleBookingNextSlot()))
      .subscribe((scheduleTimeSlot) => {
        this.nextAvailableTimeSlot$.next(scheduleTimeSlot);
      });
  }

  /**
   * Retrieve all active bookings for the given user.
   *
   * @param user: User - The authenticated user.
   * @returns Observable<any> - An observable containing the result of the HTTP request.
   */
  retrieveAllActiveBookings(user: User):  Observable<any> {
    if(new Date().getTime() - this.lastBookinkRequestedTime.getTime()< 2 * 1000 && this.bookings.getValue().length > 0) {
      return of(this.bookings.getValue())
    }
    const endpoint = 'booking/user';
    const httpParams = new HttpParams().set('id', user.id);
    return this.api.get(endpoint, httpParams).pipe(
      map((res: any) => {
        return res.Bookings
          .map((res: any) => this.createBookingFromRequest(res))
          .sort((bookingA: Booking, bookingB: Booking) => bookingA.createdDate < bookingB.createdDate ? 1 : -1)
    }),
    tap((bookings: Booking[]) => {
      this.bookings.next(bookings);
      this.lastBookinkRequestedTime = new Date();
    }))
  }

  createPaymentFromRequest(paymentData: any): Payment{
    return {
      amount: paymentData.amount,
      expiryDate: new Date(paymentData.expiry_date),
      id: paymentData.id,
      paymentMethods: paymentData.payment_methods,
      spgContext: paymentData.spgContext,
      status: paymentData.status,
      transactionID: paymentData.transactionID,
    }
  }

  /**
   * Retrieve a specific booking for the given user by booking ID.
   *
   * @param user - The authenticated user.
   * @param bookingId - The ID of the booking to retrieve.
   * @returns Observable<Booking> - An observable containing the result of the HTTP request.
   */
  retrieveBookingByUserAndBookinId(user: User, bookingId: string): Observable<{
    booking: Booking,
    checkout: Payment
  }>{
    const endpoint = 'booking/get-booking';
    const httpParams = new HttpParams()
      .set('id', user.id)
      .set('booking_id', bookingId);

      return this.api.get(endpoint, httpParams, {}, false)
        .pipe(map((res: any) => {
        return {
          booking: this.createBookingFromRequest(res.booking),
          checkout: this.createPaymentFromRequest(res.checkout)
        }
      }));
  }
  // /**
  //  * Retrieve a specific booking for the given user by booking ID.
  //  *
  //  * @param user - The authenticated user.
  //  * @param bookingId - The ID of the booking to retrieve.
  //  * @returns Observable<Booking> - An observable containing the result of the HTTP request.
  //  */
  // retrieveBookingByUserAndBookinId(user: User, bookingId: string): Observable<{
  //   booking: Booking,
  //   checkout: Payment
  // }>{
  //   const endpoint = 'booking/get-booking';
  //   const httpParams = new HttpParams()
  //     .set('id', user.id)
  //     .set('booking_id', bookingId);

  //     return this.api.get(endpoint, httpParams, {}, false)
  //       .pipe(map((res: any) => {
  //       return {
  //         booking: this.createBookingFromRequest(res.booking),
  //         checkout: this.createPaymentFromRequest(res.checkout)
  //       }
  //     }));
  // }

  /**
   * Retrieves all finished bookings from the server.
   *
   * This function sends a request to the 'bookings' endpoint to fetch information
   * about finished bookings. It filters the locations based on their type, selecting
   * only those with type 'Interface'.
   *
   * @returns An Observable containing the response from the server.
   * The response structure may vary based on your API, so replace 'any' with the
   * appropriate type.
  */
  retrieveAllFinishedBookings(): Observable<any> {
    const endpoint = 'bookings';
    // const locations = this.locationService.locations
    //   .getValue()
    //   .filter(({ type }) => type == 'Interface');

      // TEST MOD
    return of([])
  }

  /**
   * Retrieves the next available schedule time slot from the server.
   *
   * @returns An Observable containing information about the next available time slot.
   * The emitted value is a ScheduleTimeSlot object with the following properties:
   *   - interval: The time interval between time slots in minutes.
   *   - latestSlot: The timestamp of the latest available time slot.
   *   - nextSlot: The timestamp of the next available time slot.
   *   - serverTime: The current server time when the request was made.
   */
  scheduleBookingNextSlot(): Observable<ScheduleTimeSlot> {
    const endpoint = 'schedules/next_slot';
    const params: HttpParams = new HttpParams();
    return this.api.get(endpoint, params, null, false).pipe(
      map((res: any) => ({
        interval: res.interval,
        latestSlot: res.latest_slot,
        nextSlot: res.next_slot,
        serverTime: res.server_time,
      }))
    );
  }

  /**
   * Creates a new booking with the specified details.
   *
   * @param bookedBy The user who made the booking.
   * @param user Optional user information for the booking.
   * @returns A new Booking object.
  */
  createNewBooking(bookedBy: User): Booking {
    const user: User = this.portoCardUser ? this.portoCardUser : bookedBy;
    const nif: string = user && user.nif? user.nif : '';
    const phoneNumber: string = user && user.phoneNumber ? user.phoneNumber : '';

    const booking: Booking = {
      bookedBy: bookedBy,
      createdDate: new Date(),
      date: this.selectNextAvailableTimeSlot(),
      endLocation: undefined,
      id: '',
      startLocation: undefined,
      state: BookingState.PENDING,
      user: user,
      nif,
      phoneNumber,
      modified: new Date(),
      nPassengers: 1,
    }

    return booking;
  }

  /**
   * Selects the next available time slot for a booking.
   *
   * @returns A Date object representing the next available time slot.
   */
  selectNextAvailableTimeSlot(): Date {
    const minDate = new Date(
      new Date().getTime() +
        this.nextAvailableTimeSlot$.value.nextSlot * 60 * 1000
    );
    let nextAvailableDate = new Date(minDate.getTime());
    nextAvailableDate.setMinutes(0);
    nextAvailableDate.setSeconds(0);
    nextAvailableDate.setMilliseconds(0);

    while (nextAvailableDate.getTime() < minDate.getTime()) {
      nextAvailableDate = new Date(
        nextAvailableDate.getTime() +
          this.nextAvailableTimeSlot$.value.interval * 60 * 1000
      );
    }

    return nextAvailableDate;
  }

  /**
   * Posts a new booking to the server.
   *
   * @param booking The booking to be posted.
   * @returns An Observable containing the response from the server.
   */
  postNewBooking(booking: Booking): Observable<{
    booking?: Booking,
    error?: string
  }> {
    const endpoint = 'booking/add';
    const body: any = {
      booked_by_id: this.userService.user$.getValue()!.id,
      user_id: booking.user.id,
      // companion: booking.companion ? booking.companion.portoCard : '',
      start_point: booking.startLocation!.id,
      end_point: booking.endLocation!.id,
      date: parseInt(booking.date.getTime() / 1000 + ''),
      phone_number: booking.phoneNumber,
      nif: booking.nif,
      n_passegers: booking.nPassengers,
    };

    if(booking.companion){
      body['companion'] = booking.companion.portoCard;
    }

    return this.api.post(endpoint, body, null, false)
      .pipe(map((res: any) => {
        if(res['booking']){
          return {
            booking: this.createBookingFromRequest(res['booking']),
          }
        }
        return {
          error: res['error']['mensagem_erro']
        }
      }));
  }

  /**
   * Checks if a booking is valid.
   *
   * @param booking The booking to be validated.
   * @returns True if the booking is valid, false otherwise.
   */
  isBookingValid(booking: Booking): boolean {

    // In Visacar Project, theres no valid times to confirm a booking
    // const minTimestamp = new Date().getTime() / 1000 + this.nextAvailableTimeSlot$.value.nextSlot * 60;
    if (!booking.startLocation || !booking.endLocation) {
      return false;
    }

    // if (booking.date.getTime() / 1000 < minTimestamp) {
    //   return false;
    // }

    // if(booking.date.getHours() < 6){
    //   return false;
    // }

    return true;
  }

  /**
   * Creates a Booking object from the provided booking data.
   *
   * @param bookingData The data to create a Booking object.
   * @returns A new Booking object.
   */
  createBookingFromRequest(bookingData: any): Booking{
    const user = this.userService.createUserFromRequest(bookingData['user']);
    const bookedBy = bookingData['booked_by'] ? this.userService.createUserFromRequest(bookingData['booked_by']) : user;
    const companion = bookingData['companion'] ? this.companionService.createCompanionFromRequest(bookingData['companion']) : undefined;

    return {
      id: bookingData['id'],
      bookedBy: bookedBy,
      user: user,
      companion: companion,
      startLocation: this.pointService.createPointFromRequest(bookingData['start_point']),
      endLocation: this.pointService.createPointFromRequest(bookingData['end_point']),
      state: this.mapBookingState(bookingData['state']),
      date: new Date(bookingData['date_ts'] * 1000),
      createdDate: new Date(bookingData['created_date']),
      nif: bookingData['nif'],
      phoneNumber: bookingData['phone_number'],
      modified: new Date(),
      nPassengers: 1,
    }
  }

  mapBookingState(value: string){
    return Object.values(BookingState).find((enumValue) => enumValue === value) as BookingState;
  }

  /**
   * Searches for booking information based on the specified date and search field.
   *
   * @param date The date for the booking search.
   * @param searchField The search field for filtering bookings.
   * @returns An Observable containing the booking search results.
   */
  operatorSearchBookingList(date: Date, searchField: string): Observable<any> {

    const fakeResponse: { data: Booking[] } = {
      data: [
        {
          bookedBy: this.userService.user$.getValue()!,
          createdDate: new Date(),
          date: new Date(),
          id: '1',
          state: BookingState.PENDING,
          user: this.userService.user$.getValue()!,
          nif: '',
          phoneNumber: '',
          endLocation: {
            id: 1,
            name: 'Porto Airport',
            type: 'Interface',
            lat: 41.2376,
            lon: -8.6707,
            formattedAddress: 'Porto Airport',
          },
          startLocation: {
            id: 2,
            name: 'Trindade',
            type: 'Interface',
            lat: 41.1529,
            lon: -8.6096,
            formattedAddress: 'Trindade'
          },
          nPassengers: 1,
          modified: new Date(),
        },
      ],
    };

    return this.api.testRequest(fakeResponse);
  }

  sendSms(booking: Booking, phoneNumber: string, paymentType: string): Observable<any> {
    const endpoint = 'booking/send-sms';
    const body: any = { 
      "booking_id": booking.id,
      "payment_type": paymentType,
      "phone_number": phoneNumber
    };

    return this.api.post(endpoint, body, null, true);
  }


  getAllBookings(): Observable<any[]> {
    // return this.http.get<any>(this.getAllBookingsUrl).pipe(map(response => response.Bookings));
    return this.api.get(this.getAllBookingsEndpoint).pipe(map((response: any) => response.Bookings));
  }

  getAliveRequests(): Observable<any[]> {
    // return this.http.get<any>(this.getAllBookingsUrl).pipe(map(response => response.Bookings));
    return this.api.get(this.getAliveRequestsEndpoint, undefined, false).pipe(map((response: any) => response.Requests));
  }
  

  getAllBookingsByStartingLocationId(id:any): Observable<any[]> {
    console.log("Entrou endpoint com:", id)
    const body: any = { 
      "id": id
    };
    // return this.http.get<any>(this.getAllBookingsUrl).pipe(map(response => response.Bookings));
    return this.api.post(this.getBookingByStartingLocationIdEndpoint, body).pipe(map((response: any) => response.Bookings));
  }

  cancelBooking(bookingId: string): Observable<boolean> {
    const body = { booking_id: bookingId };
    console.log("Entrou Serviço com bookingId", bookingId);
    return this.api.post<{ Canceled: boolean }>(this.cancelBookingEndpoint, body)
      .pipe(map(response => response.Canceled));
  }
}
