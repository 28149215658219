<!-- src/app/not-found/not-found.component.html -->

<div class="container mt-5">
    <div class="row justify-content-center">
        <div class="col-md-8 text-center">
            <h1 class="display-1">404</h1>
            <p class="lead">Oops! The page you are looking for might be in another castlex.</p>
            <p>Let's get you back <a routerLink="/">home</a>.</p>
        </div>
    </div>
</div>